import React from 'react';
import { Checkmark, Info } from 'react-bytesize-icons';

const ListItem = (props) => (
  <li>
    <Checkmark height={14} width={14} strokeWidth="15%" id="" />
    <span dangerouslySetInnerHTML={{ __html: props.children }} />
    {props.tip ? (
      <span data-tip={props.tip}>
        <Info width={16} height={16} strokeWidth="2px" id="" />
      </span>
    ) : null}
  </li>
);

export default ListItem;
