import { css } from 'astroturf';
import fetchJsonp from 'fetch-jsonp';
import React from 'react';

import Column from './Column';
import SignUp from './SignUp';

const styles = css`
  .currency {
    margin: 1rem auto -2.5rem;
    max-width: 75rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    padding-left: env(safe-area-inset-left, 0);
    padding-right: env(safe-area-inset-right, 0);
    color: #007ab3;
    font-size: 0.8125rem;

    @media (min-width: 1201px) {
      margin: 0rem auto -1.5rem;
      justify-content: flex-end;
    }
  }
  .flexwrap {
    margin: 2rem auto 1rem;
    max-width: 75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding-left: env(safe-area-inset-left, 0);
    padding-right: env(safe-area-inset-right, 0);

    @media (min-width: 1201px) {
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
    }
  }
`;

const currencies = {
  USD: 'US Dollar',
  EUR: 'Euro',
  GBP: 'Pound Sterling',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  HKD: 'Hong Kong Dollar',
  HUF: 'Hungarian Forint',
  INR: 'Indian Rupee',
  ILS: 'Israeli Shekel',
  JPY: 'Japanese Yen',
  KRW: 'South Korean Won',
  MXN: 'Mexican Peso',
  NOK: 'Norwegian Krone',
  NZD: 'New Zealand Dollar',
  PLN: 'Polish Zloty',
  RUB: 'Russian Ruble',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  THB: 'Thai Baht',
  TRY: 'Turkish Lira',
  TWD: 'New Taiwan Dollar',
  UAH: 'Ukrainian Hryvnia',
};

const PRICING_IDS = {
  proYearly: 599947,
  proMonthly: 599945,
  teamYearly: 599949,
  teamMonthly: 599948,
  proYearlyOld: 565952,
  enterpriseMonthly: 841306,
};

const pricing = {
  yearly: {
    pro: {
      price: '  ',
      id: PRICING_IDS.proYearly,
      monthId: 566022,
    },
    team: {
      price: '   ',
      id: PRICING_IDS.teamYearly,
      monthId: 566023,
    },
    oldPro: {
      id: PRICING_IDS.proYearlyOld,
    },
  },
  monthly: {
    pro: {
      price: '   ',
      id: PRICING_IDS.proMonthly,
    },
    team: {
      price: '   ',
      id: PRICING_IDS.teamMonthly,
    },
    enterprise: {
      price: '   ',
      id: PRICING_IDS.enterpriseMonthly,
    },
  },
};

const getCoupon = () => {
  if (typeof window !== 'undefined') {
    const params = new URL(document.location).searchParams;
    if (params.get('coupon')) {
      const coupon = params.get('coupon');
      sessionStorage.coupon = coupon;
    }
    return sessionStorage.coupon;
  }

  return false;
};

const getCurrency = () =>
  fetchJsonp(`https://checkout.paddle.com/api/2.0/prices?product_ids=${PRICING_IDS.proYearly}`)
    .then((response) => response.json())
    .then((json) => currencies[json.response.products[0].currency])
    .catch(() => false);

const emailQuote = `<span style="display:block;width: 300px;padding:0.5rem 0;">"One of the best SaaS email support out there. If you reply with a kudos, question,
                    or any other random thought, you'll hear from Kilian. If they say they're going to
                    get back to you, they get back to you." - <i>Drew, Creative Director</i></span>`;

class Table extends React.Component {
  constructor() {
    super();

    this.state = {
      country: '',
      oldProYearly: '',
      proYearly: '',
      currency: false,
      proYearlyHasVAT: false,
      proYearlyVAT: '',
      proYearlyPerMonth: pricing.yearly.pro.price,
      proMonthly: pricing.monthly.pro.price,
      proMonthlyHasVAT: false,
      proMonthlyVAT: '',
      teamYearly: '',
      teamYearlyPerMonth: pricing.yearly.team.price,
      teamYearlyHasVAT: false,
      teamYearlyVAT: '',
      teamMonthly: pricing.monthly.team.price,
      teamMonthlyHasVAT: false,
      teamMonthlyVAT: '',
      enterpriseMonthly: pricing.monthly.enterprise.price,
      pricingPopup: {
        id: '',
        visible: false,
        name: 'Polypane',
        priceYearly: false,
        total: 0,
        checkoutprice: 0,
        priceInfo: {},
        sub: 'pro',
      },
      coupon: getCoupon(),
      highLightedPlan: false,
    };
  }

  componentDidMount() {
    getCurrency().then((currency) => {
      this.setState({ currency });
    });

    const params = new URL(document.location).searchParams;
    if (params.get('plan')) {
      this.setState({ highLightedPlan: params.get('plan') });
    }

    const conditionallySetupPaddle = () => {
      if (window.Paddle) {
        this.setupPaddle();
      } else {
        setTimeout(() => conditionallySetupPaddle(), 100);
      }
    };

    conditionallySetupPaddle();
  }

  setupPaddle = () => {
    const { Paddle } = window;
    const ref = this;

    Paddle.Setup({
      vendor: 39438,
      debug: false,
      eventCallback(data) {
        if (
          [
            'Checkout.Loaded',
            'Checkout.Coupon.Applied',
            'Checkout.Coupon.Remove',
            'Checkout.Vat.Applied',
            'Checkout.Vat.Remove',
          ].includes(data.event)
        ) {
          const { id } = data.eventData.product;
          const { currency } = data.eventData.checkout.recurring_prices.customer;

          let currentprice = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
          }).format(data.eventData.checkout.prices.customer.total);

          if (currentprice.endsWith('.00')) {
            [currentprice] = currentprice.split('.00');
          }

          if (currentprice.startsWith('US$')) {
            currentprice = currentprice.replace('US$', '$');
          }

          let price = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
          }).format(data.eventData.checkout.recurring_prices.customer.total);

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }

          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }

          const hasVat = data.eventData.checkout.recurring_prices.customer.total_tax != '0.00';
          let vat = new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
          }).format(data.eventData.checkout.recurring_prices.customer.total_tax);

          if (vat.endsWith('00')) {
            [vat] = vat.split('.00');
          }

          const currentState = ref.state.pricingPopup;
          currentState.id = id;
          currentState.checkoutprice = currentprice;
          currentState.total = price;
          currentState.priceInfo = {
            id,
            price,
            vat,
            hasVat,
          };
          ref.setState({ pricingPopup: currentState });
        }
      },
    });

    Object.entries(pricing.yearly).forEach((item) => {
      if (item[0] === 'pro') {
        Paddle.Product.Prices(item[1].id, (prices) => {
          let price = prices.recurring.price.gross;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }

          this.setState({
            country: prices.country,
            proYearly: price,
            proYearlyVAT: prices.recurring.price.tax,
            proYearlyHasVAT: prices.recurring.price.net !== prices.recurring.price.gross,
          });
        });

        Paddle.Product.Prices(item[1].monthId, (prices) => {
          let price = prices.recurring.price.gross;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({ proYearlyPerMonth: price });
        });
      } else if (item[0] === 'team') {
        Paddle.Product.Prices(item[1].id, (prices) => {
          let price = prices.recurring.price.gross;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({
            teamYearly: price,
            teamYearlyVAT: prices.recurring.price.tax,
            teamYearlyHasVAT: prices.recurring.price.net !== prices.recurring.price.gross,
          });
        });
        Paddle.Product.Prices(item[1].monthId, (prices) => {
          let price = prices.recurring.price.gross;
          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({ teamYearlyPerMonth: price });
        });
      } else if (item[0] === 'oldPro') {
        Paddle.Product.Prices(item[1].id, (prices) => {
          let price = prices.recurring.price.net;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({
            oldProYearly: price,
          });
        });
      }
    });
    Object.entries(pricing.monthly).forEach((item) => {
      if (item[0] === 'pro') {
        Paddle.Product.Prices(item[1].id, (prices) => {
          let price = prices.recurring.price.gross;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({
            proMonthly: price,
            proMonthlyVAT: prices.recurring.price.tax,
            proMonthlyHasVAT: prices.recurring.price.net !== prices.recurring.price.gross,
          });
        });
      } else if (item[0] === 'team') {
        Paddle.Product.Prices(item[1].id, (prices) => {
          let price = prices.recurring.price.gross;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({
            teamMonthly: price,
            teamMonthlyVAT: prices.recurring.price.tax,
            teamMonthlyHasVAT: prices.recurring.price.net !== prices.recurring.price.gross,
          });
        });
      } else if (item[0] === 'enterprise') {
        Paddle.Product.Prices(item[1].id, (prices) => {
          let price = prices.recurring.price.gross;

          if (price.endsWith('00')) {
            [price] = price.split('.00');
          }
          if (price.startsWith('US$')) {
            price = price.replace('US$', '$');
          }
          this.setState({
            enterpriseMonthly: price,
          });
        });
      }
    });
  };

  setSignUpVisible = (pricingPopup) => {
    const ref = this;
    ref.setState({ pricingPopup });
  };

  render() {
    const { state, props } = this;
    const { pricingPopup, coupon, currency } = state;

    return (
      <>
        <div className={styles.currency}>
          {currency ? (
            <>
              <span>
                prices shown in <strong>{currency}</strong>
              </span>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </div>
        <div className={styles.flexwrap}>
          <Column
            freeTrial={true && !state.highLightedPlan}
            priceYearly={props.priceYearly}
            name="Free trial"
            highlighted={false}
            priceInfo={[
              {
                price: ' ',
                id: pricing.yearly.pro.id,
                vat: ' ',
                hasVat: false,
              },
              {
                price: ' ',
                id: pricing.monthly.pro.id,
                vat: ' ',
                hasVat: false,
              },
            ]}
            localPrice={' '}
            desc="Use for free for 14 days"
            smalldesc=""
            benefits={[
              'Full access to the app',
              '<b>No credit card needed</b>',
              'Use all features',
              'Alone or <b>with your team</b>',
              '<b>Onboarding chat support</b>',
              'On Win, Mac and Linux',
            ]}
            buttonText="Start free trial"
            setSignUpVisible={this.setSignUpVisible}
            togglePricing={props.togglePricing}
          />

          <Column
            priceYearly={props.priceYearly}
            name="Individual"
            highlighted={state.highLightedPlan === 'Individual'}
            priceInfo={[
              {
                price: state.proYearlyPerMonth,
                id: pricing.yearly.pro.id,
                vat: state.proYearlyVAT,
                hasVat: state.proYearlyHasVAT,
              },
              {
                price: state.proMonthly,
                id: pricing.monthly.pro.id,
                vat: state.proMonthlyVAT,
                hasVat: state.proMonthlyHasVAT,
              },
            ]}
            localPrice={props.priceYearly ? state.proYearly : state.proMonthly}
            oldLocalPrice={state.oldProYearly}
            desc="Perfect for freelancers, solo devs or personal projects."
            smalldesc=""
            benefits={[
              'Single user',
              'Use on <strong>3 computers</strong>',
              'All features',
              'Frequent updates',
              'Pause when needed',
              'Email support',
            ]}
            tooltips={[
              false,
              `No need to switch licences between computers, run Polypane on 3 computers simultaneously.`,
              false,
              false,
              `Only do web dev occasionally? Pause your renewal and you don't get charged until you resume your subscription.`,
              emailQuote,
            ]}
            buttonText="Subscribe"
            setSignUpVisible={this.setSignUpVisible}
            togglePricing={props.togglePricing}
          />

          <Column
            priceYearly={props.priceYearly}
            name="Business"
            highlighted={state.highLightedPlan === 'Business'}
            priceInfo={[
              {
                price: state.teamYearlyPerMonth,
                id: pricing.yearly.team.id,
                vat: state.teamYearlyVAT,
                hasVat: state.teamYearlyHasVAT,
              },
              {
                price: state.teamMonthly,
                id: pricing.monthly.team.id,
                vat: state.teamMonthlyVAT,
                hasVat: state.teamMonthlyHasVAT,
              },
            ]}
            localPrice={props.priceYearly ? state.teamYearly : state.teamMonthly}
            desc="Use Polypane with your entire&nbsp;team."
            benefits={[
              'Entire team (with <strong>10 users</strong>)',
              'Use on <strong>3 computers</strong> per user',
              'All features',
              'Frequent updates',
              'Email &amp; <b>Live chat</b> support',
              'Priority feature requests',
              'Manage user access',
              'Centralized billing',
            ]}
            tooltips={['Need more? No problem, contact us!', false, false, false, emailQuote, false, false]}
            buttonText="Subscribe"
            setSignUpVisible={this.setSignUpVisible}
            togglePricing={props.togglePricing}
          />

          <Column
            name="Enterprise"
            highlighted={state.highLightedPlan === 'Enterprise'}
            priceInfo={[
              {
                price: 'Custom',
              },
              {
                price: 'Custom',
              },
            ]}
            localPrice=" "
            desc="For large companies with multiple&nbsp;teams."
            benefits={[
              'Starts at 20+ users',
              'Use on <strong>3 computers</strong> per user',
              'All features',
              'Frequent updates',
              'Email &amp; <b>Live chat</b> support',
              'Priority feature requests',
              'Manage user access',
              '<b>Custom</b> billing options',
              'Single sign-on',
              'Training & onboarding',
            ]}
            tooltips={[false, false, false, false, emailQuote, false, false]}
            buttonText="Book a demo"
            togglePricing={props.togglePricing}
          />

          <SignUp
            visible={pricingPopup.visible}
            setSignUpVisible={this.setSignUpVisible}
            id={pricingPopup.id}
            name={pricingPopup.name}
            priceYearly={pricingPopup.priceYearly}
            total={pricingPopup.total}
            checkoutprice={pricingPopup.checkoutprice}
            priceInfo={pricingPopup.priceInfo}
            coupon={coupon}
            togglePricing={this.togglePricing}
            pricingPopup={pricingPopup}
            country={this.state.country}
          />
        </div>
      </>
    );
  }
}
export default Table;
