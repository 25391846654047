import { css } from 'astroturf';
import React, { Component } from 'react';
import { Lock } from 'react-bytesize-icons';

import Brands from '../components/Brands';
import CalculatorCTA from '../components/CalculatorCTA';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import JSONLD from '../components/jsonld';
import Link from '../components/link';
import FAQ from '../components/pricetable/FAQ';
import Table from '../components/pricetable/Table';
import Testimonials from '../components/pricetable/Testimonials';
import Toggle from '../components/pricetable/Toggle';
import Layout from '../layout';

const styles = css`
  .pricingtable {
    margin-top: -2rem;
    text-align: center;

    @media (min-width: 480px) {
      margin-top: -4rem;
    }

    & > p {
      margin: 2rem auto;
      padding: 0 2rem;
      max-width: 50rem;
      font-weight: 400;
      color: rgb(51, 51, 56);
      opacity: 1;
    }

    & > small {
      margin-top: 0rem;
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: rgb(51, 51, 56);
      display: flex;
      gap: 0.25rem 0.5rem;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      & strong {
        color: #008600;
        font-weight: inherit;
      }

      & svg {
        vertical-align: text-top;
        margin-right: 0.25rem;
      }

      & [aria-hidden='true'] {
        display: none;
        @media (min-width: 1000px) {
          display: block;
          opacity: 0.25;
        }
      }
    }
  }

  .svg {
    fill: currentColor;
    height: 1rem;
    width: 1rem;
    vertical-align: baseline;
    transform: translateY(2px);
    margin-right: 4px;
  }

  .extraInfo {
  }
`;

class Page extends Component {
  constructor() {
    super();

    this.state = {
      priceYearly: true,
    };
  }

  togglePricing = (value) => {
    this.setState({ priceYearly: value });
  };

  render() {
    return (
      <Layout page="pricing">
        <SEO title="Pricing" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <h1 className={styles.h1}>Pricing</h1>
        </PageHeader>

        <div className={styles.pricingtable}>
          <Toggle toggleState={this.state.priceYearly} toggle={this.togglePricing} />
          <Table priceYearly={this.state.priceYearly} togglePricing={this.togglePricing} />
          <small className={styles.extraInfo}>
            <span>
              <Lock width={16} height={16} /> Secure payment with <strong>credit card or PayPal</strong> through Paddle.
            </span>
            <span aria-hidden="true">|</span>
            <span>Cancel anytime.</span>
            <span aria-hidden="true">|</span>
            <span>Tax (such as VAT) may be deducted after filling in company details.</span>
          </small>
          <p>
            <svg className={styles.svg} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>GitHub icon</title>
              <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
            </svg>{' '}
            Free for students via the GitHub Student Developer Pack. <Link to="/github-students/">Learn more</Link>.
          </p>

          <Testimonials />
          <CalculatorCTA />
          <div style={{ height: '4rem' }} />
          <FAQ />
        </div>
        <JSONLD />
      </Layout>
    );
  }
}

export default Page;
