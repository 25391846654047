import { css } from 'astroturf';
import React, { Component } from 'react';
import { Close } from 'react-bytesize-icons';

const styles = css`
  .wrap {
    position: fixed;
    background: rgba(220, 220, 220, 0.85) none repeat scroll 0% 0%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    z-index: 100;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
    transition: 0.25s ease-in-out all;

    @media (min-width: 30rem) and (min-height: 45rem) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .visible {
    opacity: 1;
    pointer-events: all;
  }
  .contactForm {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    padding: 2rem;
    max-width: 30rem;
    width: 100%;

    @media (min-width: 30rem) and (min-height: 45rem) {
      border-radius: 0.5rem;
      max-width: 30rem;
      margin-top: 90px;
      box-shadow: rgba(0, 0, 0, 0.025) 0 0 0 1px, 0 1px 10px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.06);
    }

    @media (min-width: 50rem) {
      padding: 2rem;
    }

    & > div {
      position: relative;
      max-width: 40rem;
      width: 100%;
      margin: 0 auto;

      & div {
        color: red;
        margin-top: 1rem;
        font-weight: bold;
      }
    }
    & > div > * {
      display: block;
    }

    & h3,
    & legend {
      font-size: 1.5rem;
      color: #107db5;
      font-weight: 400;
    }

    & label {
      margin: 0.5rem 0 0.25rem;
    }

    & input,
    & textarea {
      font-size: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 70, 150, 0.4);
      width: 100%;
    }

    & textarea {
      height: 6rem;
    }

    & div button {
      margin-top: 2rem;
      float: right;
      clear: right;
      border: 0;
      border-radius: 8px;
      padding: 0 1rem;
      line-height: 2rem;
      background: #107db5;
      font-weight: 600;
      font-size: 1.17rem;
      color: #fff;
      transition: 0.25s ease-in-out background;

      &:hocus {
        background: #67b26f;
      }
    }

    & small {
      opacity: 0.75;
      float: right;
      clear: right;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: 0;
    color: #107db5;
    padding: 1rem;
    opacity: 0.5;
    transition: 0.25s ease-in-out opacity;

    &:hocus {
      opacity: 1;
    }
  }
`;

class Page extends Component {
  constructor() {
    super();

    this.state = {
      mailSent: false,
      message: '',
      status: '',
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements[0].value;
    const email = e.target.elements[1].value;
    const phone = e.target.elements[2].value;
    const company = e.target.elements[3].value;
    const size = e.target.elements[4].value;
    const msg = e.target.elements[5].value;

    if (name && email && company && size) {
      const link = 'https://dashboard.polypane.app/request-demo';
      const data = {
        name,
        email,
        phone,
        company,
        size,
        msg,
      };
      const result = await fetch(link, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (result.ok) {
        this.setState({
          mailSent: true,
          status: 'ok',
          message: `<h3>Request sent</h3><p>We'll contact you via e-mail soon.</p>`,
        });

        if (typeof window !== 'undefined' && window.umami) {
          window.umami.track((data) => ({ ...data, url: '/pricing/quote-form-sent/', title: 'Quote form sent' }));
        }


        if (typeof window !== 'undefined' && window.ga) {
          window.ga('send', 'pageview', `${window.location.pathname}request-form-sent`);
        }
      } else {
        this.setState({
          mailSent: false,
          status: 'error',
          message: `<h3>Request not sent!</h3><p>There was an error sending this form. please try again later.</p>`,
        });
      }
    } else {
      this.setState({
        mailSent: false,
        status: 'error',
        message: `<h3>Request not sent!</h3><p>All form fields are required</p>`,
      });
    }
  };

  resetForm = () => {
    this.setState({
      mailSent: false,
      status: '',
      message: '',
    });
  };

  hideForm = () => {
    this.props.setVisible(false);

    setTimeout(() => {
      this.resetForm();
    }, 500);
  };

  preventPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { visible } = this.props;
    const { mailSent, status, message } = this.state;

    return (
      <div className={[styles.wrap, visible && styles.visible].join(' ')} onClick={this.hideForm}>
        <form className={styles.contactForm} onSubmit={this.handleSubmit} onClick={this.preventPropagation}>
          {mailSent && status === 'ok' && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {!mailSent && (
            <div>
              <legend>Book a demo</legend>
              <p>We’d love to talk about your use case. Get a demo by the founder.</p>
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                defaultValue=""
                name="name"
                id="name"
                autoComplete="name"
                required
                autoFocus
                onFocus={this.resetForm}
              />

              <label htmlFor="email2">E-mail *</label>
              <input
                type="email"
                defaultValue=""
                name="email2"
                id="email2"
                autoComplete="email"
                required
                onFocus={this.resetForm}
              />

              <label htmlFor="phone">Phone number (optional)</label>
              <input type="text" defaultValue="" name="phone" id="phone" autoComplete="tel" onFocus={this.resetForm} />

              <label htmlFor="company">Organization *</label>
              <input
                type="text"
                defaultValue=""
                name="company"
                id="company"
                autoComplete="organization"
                required
                onFocus={this.resetForm}
              />

              <label htmlFor="size">Team size *</label>
              <input type="number" defaultValue="" min="0" name="size" id="size" required onFocus={this.resetForm} />

              <label htmlFor="msg">Remarks (optional)</label>
              <textarea name="msg" id="msg" onFocus={this.resetForm} />
              <small>* required</small>

              {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}

              <button type="submit">Request Demo</button>
            </div>
          )}
          <button
            type="button"
            aria-label="close modal"
            className={styles.close}
            onClick={this.hideForm}
            data-tip="Close"
          >
            <Close width={24} height={24} id="" />
          </button>
        </form>
      </div>
    );
  }
}

export default Page;
